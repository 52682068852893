@font-face {
font-family: '__Borna_a32782';
src: url(/_next/static/media/4d1f729c38484637-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Borna_a32782';
src: url(/_next/static/media/ab7e58a3b0c89569-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__Borna_a32782';
src: url(/_next/static/media/4fbb1c77d5fca651-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Borna_a32782';
src: url(/_next/static/media/203f09c1123a8f71-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__Borna_a32782';
src: url(/_next/static/media/87a2ea3e34c4cef5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__Borna_a32782';
src: url(/_next/static/media/4c924d0d51d90d01-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__Borna_a32782';
src: url(/_next/static/media/315c7567b3942571-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__Borna_a32782';
src: url(/_next/static/media/e5c12fcbc2a3ecaa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__Borna_Fallback_a32782';src: local("Arial");ascent-override: 87.32%;descent-override: 22.97%;line-gap-override: 0.00%;size-adjust: 108.82%
}.__className_a32782 {font-family: '__Borna_a32782', '__Borna_Fallback_a32782'
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/35657cdc09596c38-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/2a8775f0adb5cb0d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: italic;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/215822d81afb6a23-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/d59064c9e37a52ae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/ff914f62d8d74f46-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/39857e3ef1ac1add-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/e40c29903bd5b252-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/d086f7b2b6c3d9fe-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/d18e48123e385d5f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/8c49d5735d0d31dc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/8ae45c158e54d4de-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/467019e20722f285-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__Strawford_6c2e9c';
src: url(/_next/static/media/c1ee380344da1ee8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__Strawford_Fallback_6c2e9c';src: local("Arial");ascent-override: 93.65%;descent-override: 31.75%;line-gap-override: 0.00%;size-adjust: 100.80%
}.__className_6c2e9c {font-family: '__Strawford_6c2e9c', '__Strawford_Fallback_6c2e9c'
}

